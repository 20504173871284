import { combineReducers } from "redux";
import * as types from "./types";
import * as orderEventTypes from "../orderEvents/types";
import {
  getDeliveredOrderStats,
  getOrdersStats,
  removeOrderFromList,
} from "../../../helpers/orderFunctions";
import * as orderTypes from "../order/types";

const initialOrdersState = {
  refresh_required: false,
  is_loading: true,
  count: 0,
  orders: [],
} as any;

const newOrdersReducer = (state: any = initialOrdersState, action: any) => {
  let index, newState, orderList, updatedOrderList;
  switch (action.type) {
    case types.FETCH_NEW_ORDERS_SUCCESS:
      let updatedState = getOrdersStats(action.payload.data || []);
      return { ...updatedState, is_loading: false, refresh_required: false };
    case types.SET_ORDER_MARK_AS_READY_SUCCESS:
      // Cta button click - filter and remove order on dispatch success
      updatedOrderList = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updatedOrderList };
    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      orderList = state.orders;
      orderList[index] = action.payload.data;
      newState = getOrdersStats(orderList);
      return { ...newState, refresh_required: false };
    case orderTypes.REMOVE_EXPIRED_ORDER:
      // Remove the order from list once it completes 24 hours.
      updatedOrderList = removeOrderFromList(action.payload, state.orders);
      return { ...updatedOrderList };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_READY:
      // Pusher event - filter and remove ready order
      let updateCurrentStateObject = removeOrderFromList(
        action.payload.order_id,
        state.orders
      );
      return { ...updateCurrentStateObject };

    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED:
      if (
        action.payload.order_status !== "new" ||
        action.payload.scheduled ||
        action.payload.takeaway
      ) {
        return state; // do nothing
      }

      // TODO: handle pickup order created (pickup also has new state)
      // Push order into the new orders list if it doesn't exist
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });
      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }
    // TODO: handled from header
    // case orderEventTypes.PUSHER_RECEIVED_ORDER_UPDATED:
    //   if (action.payload.order_payload == undefined || action.payload.order_payload == {}){
    //     return { ...state, count: state.count + 1, refresh_required: true };
    //   }

    //   // find order in the list
    //   index = state.orders.findIndex((order: any) => {
    //     return order.order_id === action.payload.order_id;
    //   });

    //   // if order not found
    //   if (index === -1) {
    //     return state;
    //   }

    //   let orderList = state.orders
    //   orderList[index] = action.payload.order_payload;
    //   newState = getOrdersStats(orderList);
    //   return { ...newState, refresh_required: false };
    case orderEventTypes.PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_RIDER_UNASSIGNED:
      if (
        action.payload.order_status !== "new" ||
        action.payload.scheduled ||
        action.payload.takeaway
      ) {
        return state; // do nothing
      }

      // TODO: handle pickup order created (pickup also has new state)
      // Push order into the new orders list if it doesn't exist
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });
      if (index === -1) {
        // order is not in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        orderList = state.orders;
        orderList[index] = action.payload.order_payload;
        newState = getOrdersStats(orderList);
        return { ...newState, refresh_required: false };
      }

    case orderEventTypes.PUSHER_RECEIVED_ORDER_VOIDED:
      // Pusher event - filter and remove order from current state if exists
      newState = getOrdersStats(
        state.orders.filter((order: any) => {
          return action.payload.order_id !== order.order_id;
        })
      );
      return { ...newState, refresh_required: false };

    default:
      return state;
  }
};

const unconfirmedOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  let index, newState, orderList, updatedOrderList;
  switch (action.type) {
    case types.FETCH_UNCONFIRMED_ORDERS_SUCCESS:
      let updatedState = getOrdersStats(action.payload.data || []);
      return { ...updatedState, is_loading: false, refresh_required: false };
    case types.SET_UNCONFIRMED_ORDER_CONFIRM_SUCCESS:
      // Cta button click - filter and remove order on dispatch success
      updatedOrderList = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updatedOrderList };
    case types.SET_UNCONFIRMED_ORDER_REJECT_FAIL:
      if (action.error !== undefined && action.error.status === 404) {
        updatedOrderList = removeOrderFromList(
          action.meta.previousAction.param.order_id,
          state.orders
        );
        return { ...updatedOrderList };
      }
      return state;
    case types.SET_UNCONFIRMED_ORDER_REJECT_SUCCESS:
      // Cta button click - filter and remove order on dispatch success
      updatedOrderList = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updatedOrderList };

    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      orderList = state.orders;
      orderList[index] = action.payload.data;
      newState = getOrdersStats(orderList);
      return { ...newState, refresh_required: false };
    case orderTypes.REMOVE_EXPIRED_ORDER:
      // Remove the order from list once it completes 24 hours.
      updatedOrderList = removeOrderFromList(action.payload, state.orders);
      return { ...updatedOrderList };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED:
      if (
        action.payload.order_type === "online" &&
        action.payload.order_status === "unconfirmed" &&
        !action.payload.takeaway
      ) {
        // Push order into the unconfirmed orders list if it doesn't exist
        index = state.orders.findIndex((order: any) => {
          return order.order_id === action.payload.order_id;
        });
        if (index !== -1) {
          // order is already in the list
          return state; // do nothing
        }
        if (
          action.payload.order_payload == undefined ||
          action.payload.order_payload == {}
        ) {
          // Order payload is not present
          return { ...state, count: state.count + 1, refresh_required: true };
        }
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED:
      // Pusher event - filter and remove confirmed order
      let updateCurrentStateObject = removeOrderFromList(
        action.payload.order_id,
        state.orders
      );
      return { ...updateCurrentStateObject };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_REJECTED:
      // Pusher event - filter and remove order from current state if exists
      newState = getOrdersStats(
        state.orders.filter((order: any) => {
          return action.payload.order_id !== order.order_id;
        })
      );
      return { ...newState, refresh_required: false };
    default:
      return state;
  }
};

const readyOrdersReducer = (state: any = initialOrdersState, action: any) => {
  let index, updatedOrderList;
  switch (action.type) {
    case types.FETCH_READY_ORDERS_SUCCESS:
      let newState = getOrdersStats(action.payload.data || []);
      return { ...newState, is_loading: false, refresh_required: false };
    case types.SET_ORDER_ASSIGN_RIDER_SUCCESS:
    case types.SET_ORDER_MARK_AS_PICKUP_SUCCESS:
      // Cta button click - filter and remove order on dispatch success
      let updateState = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updateState };
    case types.SET_ORDER_MARK_AS_READY_SUCCESS:
      // return { ...state, count: state.count + 1 }; // TODO: confirm experience
      return state;
    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      let orderList = state.orders;
      orderList[index] = action.payload.data;
      newState = getOrdersStats(orderList);
      return { ...newState, refresh_required: false };
    case orderTypes.REMOVE_EXPIRED_ORDER:
      // Remove the order from list once it completes 24 hours.
      updatedOrderList = removeOrderFromList(action.payload, state.orders);
      return { ...updatedOrderList };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_READY:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }

    case orderEventTypes.PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_RIDER_ASSIGNED:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });
      if (index === -1) {
        // order not in the list
        return state; // do nothing
      }
      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, refresh_required: true };
      }
      updatedOrderList = removeOrderFromList(
        action.payload.order_id,
        state.orders
      );
      return { ...updatedOrderList };

    case orderEventTypes.PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED:
      // order payload presence condition satisfied before action dispatch
      let index = state.orders.findIndex(
        (order) => order.order_id === action.payload.order_id
      );
      if (index === -1) {
        return state; // order not found, do nothing
      }
      orderList = state.orders;
      orderList[index] = action.payload.order_payload;
      newState = getOrdersStats(orderList);
      return { ...newState, refresh_required: false };

    case orderEventTypes.PUSHER_RECEIVED_RIDER_UNASSIGNED:
      if (
        action.payload.order_status !== "ready" ||
        action.payload.scheduled ||
        action.payload.takeaway
      ) {
        return state; // do nothing
      }

      // TODO: handle pickup order created (pickup also has new state)
      // Push order into the new orders list if it doesn't exist
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });
      if (index === -1) {
        // order is not in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        orderList = state.orders;
        orderList[index] = action.payload.order_payload;
        newState = getOrdersStats(orderList);
        return { ...newState, refresh_required: false };
      }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_PICKED_UP:
      // Pusher event - filter and remove order from current state if exists
      if (action.payload.order_status === "ready") {
        let updateCurrentStateObject = removeOrderFromList(
          action.payload.order_id,
          state.orders
        );
        return { ...updateCurrentStateObject };
      }
    // TODO: handled from header
    // case orderEventTypes.PUSHER_RECEIVED_ORDER_UPDATED:
    //   if (Object.keys(action.payload.order_payload || {}).length > 0) {
    //     let index = state.orders.findIndex((order: any) => {
    //       return order.order_id === action.payload.order_id;
    //     });
    //     if(index!== -1) {
    //       let newOrdersList = state.orders
    //       newOrdersList[index] = action.payload.order_payload;
    //       let newState = getOrdersStats(newOrdersList);
    //       return { ...newState, refresh_required: false };
    //     } else {
    //       return { ...state }
    //     }
    //   } else return { ...state, count: state.count+1, refresh_required: true };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_VOIDED:
      // Pusher event - filter and remove order from current state if exists
      let filterOrders = getOrdersStats(
        state.orders.filter((order: any) => {
          return action.payload.order_id !== order.order_id;
        })
      );
      return { ...filterOrders };

    default:
      return state;
  }
};

const inProgressOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  let index, updatedOrderList;
  switch (action.type) {
    case types.FETCH_IN_PROGRESS_ORDERS_SUCCESS:
      let newState = getOrdersStats(action.payload.data || []);
      return { ...newState, is_loading: false, refresh_required: false };
    case types.SET_ORDER_MARK_AS_DELIVERED_SUCCESS:
      // Cta button click - filter and remove order on dispatch success
      let updateState = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updateState };
    case types.SET_ORDER_ASSIGN_RIDER_SUCCESS:
    case types.SET_ORDER_MARK_AS_PICKUP_SUCCESS:
      // return { ...state, count: state.count + 1 }; // TODO: Confirm behaviour
      return state;
    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      let orderList = state.orders;
      orderList[index] = action.payload.data;
      newState = getOrdersStats(orderList);
      return { ...newState, refresh_required: false };
    case orderTypes.REMOVE_EXPIRED_ORDER:
      // Remove the order from list once it completes 24 hours.
      updatedOrderList = removeOrderFromList(action.payload, state.orders);
      return { ...updatedOrderList };

    case orderEventTypes.PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_PICKED_UP:
      // append order to the list if payload is received in Pusher

      if (
        action.payload.order_status === "ready" ||
        action.payload.order_status === "new"
      ) {
        return state;
      }

      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }

    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_DELIVERED:
      // Pusher event - filter and remove order from the list

      updatedOrderList = removeOrderFromList(
        action.payload.order_id,
        state.orders
      );
      return { ...updatedOrderList };
    // TODO: handled from header
    // case orderEventTypes.PUSHER_RECEIVED_ORDER_UPDATED:
    //   if (Object.keys(action.payload.order_payload || {}).length > 0) {
    //     let index = state.orders.findIndex((order: any) => {
    //       return order.order_id === action.payload.order_id;
    //     });
    //     if(index!== -1) {
    //       let newOrdersList = state.orders
    //       newOrdersList[index] = action.payload.order_payload;
    //       let newState = getOrdersStats(newOrdersList);
    //       return { ...newState, refresh_required: false };
    //     } else {
    //       return { ...state }
    //     }
    //   } else return { ...state, count: state.count+1, refresh_required: true };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_VOIDED:
      let filterOrders = getOrdersStats(
        state.orders.filter((order: any) => {
          return action.payload.order_id !== order.order_id;
        })
      );
      return { ...filterOrders };

    case orderEventTypes.PUSHER_RECEIVED_TIP_COLLECTED:
      if (
        action.payload.order_status === "ready" ||
        action.payload.order_status === "new"
      ) {
        return state;
      }

      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      // Skip if Order is not found
      if (index === -1) {
        return state;
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, refresh_required: true };
      } else {
        // Order payload is present
        orderList = state.orders;
        orderList[index] = action.payload.order_payload;
        newState = getOrdersStats(orderList);
        return { ...newState, refresh_required: false };
      }

    default:
      return state;
  }
};

const deliveredOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  switch (action.type) {
    case types.FETCH_RIDER_STATS_SUCCESS:
      let newState = getDeliveredOrderStats(action.payload.data || []);
      return { ...newState, is_loading: false, refresh_required: false };
    case types.SET_ORDER_MARK_AS_DELIVERED_SUCCESS:
      // cta from previous tab
      return { ...state, refresh_required: true };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_DELIVERED:
      return { ...state, count: state.count + 1, refresh_required: true };
    case types.SET_DELIVERED_ORDERS_REFRESH_REQUIRED:
    case orderEventTypes.PUSHER_RECEIVED_TIP_COLLECTED:
      return { ...state, refresh_required: true };
    default:
      return state;
  }
};

// TODO: Revisit this reducer for better exp
// Add an initial state for loading
const deliveredDriverOrderDetailsReducer = (state: any = [], action: any) => {
  let index, orderList;
  switch (action.type) {
    case types.FETCH_RIDER_ORDERS_SUCCESS:
      return action.payload.data;
    case types.RESET_DELIVERED_DRIVER_ORDER_DETAILS:
      return action.payload;
    case orderEventTypes.PUSHER_RECEIVED_ORDER_REPRINTED:
      index = state.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });
      if (index === -1) {
        return state;
      }
      orderList = state;
      orderList[index] = action.payload.order_payload;
      return [...orderList];
    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      orderList = state;
      orderList[index] = action.payload.data;
      return [...orderList];
    default:
      return state;
  }
};

const reducer = combineReducers({
  new_orders: newOrdersReducer,
  unconfirmed_orders: unconfirmedOrdersReducer,
  ready_orders: readyOrdersReducer,
  in_progress_orders: inProgressOrdersReducer,
  delivered_orders: deliveredOrdersReducer,
  delivered_driver_order_details: deliveredDriverOrderDetailsReducer,
});

export default reducer;
