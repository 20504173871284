import React from "react";
import { ZOMATO_GO_PARTNER_UNIQUE_ID } from "./constants";
import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";
import { hardRefresh } from "./utils";
import { formatDecimal } from "./itemCalculations";
import { FormattedMessage } from "react-intl";

const getOrdersStats = (orders: any[]) => {
  return {
    count: orders.length,
    orders: sortOrdersByTimePlaced(orders),
  };
};

const getDeliveredOrderStats = (riders: any) => {
  let count = riders.reduce((total: number, rider: any) => {
    return (total += rider.total_orders);
  }, 0);
  let filtered_riders = riders.filter((rider: any) => rider.total_orders > 0);
  return { count, orders: filtered_riders };
};

const getOrderTime = (epochTime: number) => {
  var time = new Date(epochTime)
    .toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .toLocaleUpperCase();
  if (time.split(":")[0] == "00") {
    let fixedTime: any = time.split(":");
    fixedTime[0] = "12";
    time = fixedTime.join(":");
  }
  return time;
};

const getOrderScheduledForDate = (epochTime: number) => {
  var schduled_for_day = new Date(epochTime);
  var today = new Date();
  var bool = today.toDateString() === schduled_for_day.toDateString();
  let date;
  if (bool) {
    date = "Today";
  } else {
    let day = new Intl.DateTimeFormat("en", { weekday: "long" }).format(
      schduled_for_day
    );
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      schduled_for_day
    );
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(
      schduled_for_day
    );
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      schduled_for_day
    );
    date = `${day}, ${da}-${mo}-${ye}`;
  }
  return date;
};

const filterLabels = {
  all_delivery: {
    title: <FormattedMessage id="filter.all_delivery"/>,
  },
  in_house_delivery: {
    title: <FormattedMessage id="filter.in_house_delivery"/>,
  },
  partner_delivery: {
    title: <FormattedMessage id="filter.partner_delivery"/>,
  },
  curbside_pickup: {
    title: <FormattedMessage id="filter.curbside_pickup"/>,
  },
  in_store_pickup: {
    title: <FormattedMessage id="filter.in_store_pickup"/>,
  },
  all_pickup: {
    title: <FormattedMessage id="filter.all_pickup"/>,
  },
};

const getOrderFilterOptions = (orderType: string) => {
  let filterOptions: any;
  switch (orderType) {
    case "homeDelivery":
      filterOptions = [
        {
          id: 0,
          name: filterLabels.all_delivery.title,
          active: true,
        },
        {
          id: 1,
          name: filterLabels.in_house_delivery.title,
          active: false,
        },
        {
          id: 2,
          name: filterLabels.partner_delivery.title,
          active: false,
        },
      ];
      break;
    case "pickup":
      filterOptions = [
        {
          id: 0,
          name: filterLabels.all_pickup.title,
          active: true,
        },
        {
          id: 1,
          name: filterLabels.in_store_pickup.title,
          active: false,
        },
        // { id: 2, name: <FormattedMessage id="filter.curbside_pickup" />, active: false },
      ];
      break;
    default:
      filterOptions = [];
  }
  return filterOptions;
};

const removeOrderFromList = (orderId: number, ordersList: any) => {
  let filteredOrders = ordersList.filter((order: any) => {
    return order.order_id !== orderId;
  });
  return {
    orders: sortOrdersByTimePlaced(filteredOrders),
    count: filteredOrders.length,
  };
};

const getStatusTime = (timeStamp: any) => {
  let time = new Date(timeStamp);
  let config: any = { dateStyle: "long", timeStyle: "short", hour12: true };
  let timeString = time.toLocaleString(undefined, config as any);
  let fixedTime = timeString.split(":");
  let updatedTime = fixedTime[0].split(" ");
  if (updatedTime[updatedTime.length - 1] == "00") {
    updatedTime[updatedTime.length - 1] = "12";
    fixedTime[0] = updatedTime.join(" ");
    timeString = fixedTime.join(":");
  }
  return timeString;
};

const formatTime = (seconds: number) => {
  let time;
  if (seconds < 60) {
    return seconds === 1 ? (
      <FormattedMessage id="formatTime.second" />
    ) : (
      <FormattedMessage
        id="formatTime.seconds"
        values={{ seconds: Math.round(seconds) }}
      />
    );
  } else if (seconds >= 60 && seconds < 3600) {
    time = Math.floor(seconds / 60);
    return time === 1 ? (
      <FormattedMessage id="formatTime.min" />
    ) : (
      <FormattedMessage
        id="formatTime.mins"
        values={{ minutes: Math.round(time) }}
      />
    );
  } else {
    time = Math.floor(seconds / 3600);
    return time === 1 ? (
      <FormattedMessage id="formatTime.hour" />
    ) : (
      <FormattedMessage
        id="formatTime.hours"
        values={{ hours: Math.round(time) }}
      />
    );
  }
};

const getTime = (time: any) => {
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  var ret: any = "";
  if (hrs > 0) {
    ret =
      hrs === 1 ? (
        <FormattedMessage values={{ hour: hrs }} id="getTime.hour" />
      ) : (
        <FormattedMessage values={{ hour: hrs }} id="getTime.hours" />
      );
    <FormattedMessage values={{ min: mins }} id="getTime.minutes" />;
  }
  if (hrs < 1) {
    ret =
      mins === 1 ? (
        <FormattedMessage values={{ min: mins }} id="getTime.minute" />
      ) : (
        <FormattedMessage values={{ min: mins }} id="getTime.minutes" />
      );
  }
  if (time < 60) {
    ret = <FormattedMessage id="getTime.seconds" values={{ secs: secs }} />;
  }
  return ret;
};

const addModifiersToItems = (items: any) => {
  let parentItems = items.filter(
    (item: any) => item.order_item_parent_id === 0
  );
  parentItems.map((parentItem: any) => {
    parentItem.modifiers = [];
  });

  items.map((item: any) => {
    parentItems.map((parentItem: any) => {
      if (item.order_item_parent_id === parentItem.order_item_id) {
        parentItem.modifiers.push(item);
      }
    });
  });
  return parentItems;
};

const groupOrders = (orders: any) => {
  let filteredObject: any = {};
  orders.map((order: any) => {
    if (order.order_extended_info.delivery_address.area in filteredObject) {
      filteredObject[order.order_extended_info.delivery_address.area].push(
        order
      );
    } else {
      filteredObject[order.order_extended_info.delivery_address.area] = [];
      filteredObject[order.order_extended_info.delivery_address.area].push(
        order
      );
    }
  });
  return filteredObject;
};

const useSelectedBranch = (location: any) => (location.id ? location : null);

const useSelectedBranchWithRider = (location: any, riderId: any) =>
  location.id ? location : null;

const filterOrders = (filterOption: any, orders: any) => {
  let filteredOrders = [];
  if (filterOption === filterLabels.partner_delivery.title) {
    filteredOrders = orders.filter(
      (order: any) => order.logistics_job && order.logistics_job.status != 3
    );
  }
  if (filterOption === filterLabels.in_house_delivery.title) {
    filteredOrders = orders.filter((order: any) => {
      if (!order.logistics_job) {
        return order;
      } else if (order.logistics_job.status == 3) {
        return order;
      }
    });
  }
  if (filterOption === filterLabels.all_delivery.title) {
    filteredOrders = orders;
  }
  if (filterOption === filterLabels.curbside_pickup.title) {
    filteredOrders = orders.filter(
      (order: any) => order.order_extended_info.curbside_pickup
    );
  }
  if (filterOption === filterLabels.in_store_pickup.title) {
    filteredOrders = orders.filter(
      (order: any) => !order.order_extended_info.curbside_pickup
    );
  }
  if (filterOption === filterLabels.all_pickup.title) {
    filteredOrders = orders;
  }
  return filteredOrders;
};

const getBranchName = (branches: any, locationId: any) => {
  let branchName = "";
  branches.find((branch: any) => {
    if (branch.id == locationId) {
      branchName = branch.name;
    }
  });
  return branchName;
};

const updateTotalAmount = (orders: any) => {
  let newOrders = orders.map((order: any) => {
    if (order.order_extended_info.payments.length) {
      order.order_extended_info.payments.map((payment: any) => {
        if (payment.name === "Loyalty") {
          order.order_extended_info.total_amount =
            order.order_extended_info.total_amount - payment.amount;
        }
      });
    }
    return order;
  });
  return newOrders;
};

const updateOrderTotalAmount = (order: any) => {
  let order_copy = { ...order };
  let loyalty_payment = order_copy.order_extended_info.payments.find(
    (payment: any) => {
      return payment.name === "Loyalty";
    }
  );
  let total_amount;
  if (loyalty_payment) {
    total_amount =
      order_copy.order_extended_info.total_amount - loyalty_payment.amount;
  } else {
    total_amount = order_copy.order_extended_info.total_amount;
  }
  return total_amount;
};

const getLogisticName = (logisticPartners: any, partnerId: any) => {
  let partnerName = "";
  let logisticPartnersList = logisticPartners;
  let partner = logisticPartnersList.find((logisticPartner: any) => {
    if (logisticPartner.id == partnerId) {
      return logisticPartner;
    }
  });
  if (partner) {
    partnerName = partner.name;
  }
  return partnerName;
};

const removeLoyaltyPayment = (paymentTypes: any) => {
  let payments = [...paymentTypes];
  let filteredPaymentTypes = payments.filter((payment: any) => {
    return payment.display_name != "Loyalty";
  });
  return filteredPaymentTypes;
};

const filterOrderLogisticsPartners = (
  orderLocationId: number,
  logisticsPartners: any[]
) => {
  return logisticsPartners.filter(
    (deliveryPartner: any) => deliveryPartner.location_id === orderLocationId
  );
};

const sortOrdersByTimePlaced = (orders: any[]) => {
  return [...orders].sort((a, b) => {
    return a.scheduled_for > a.placed_at
      ? a.scheduled_for - b.scheduled_for
      : a.placed_at - b.placed_at;
  });
};

const activeZomatoGoOrderCount = (orders: any[]) => {
  let count = 0;
  [...orders].forEach((order) => {
    if (
      "logistics_job" in order &&
      order.logistics_job.status !== 3 &&
      order.logistics_job.partner_unique_id === ZOMATO_GO_PARTNER_UNIQUE_ID
    ) {
      count += 1;
    }
  });
  return count;
};

const hasLocalPrint = (order: any, printSettings: any) => {
  let printRule = printSettings?.find(
    (setting: any) => setting?.location_id == order?.location_id
  );
  if (printRule) {
    if (!printRule?.category_print_rule_enabled) {
      return printRule.order_type_rules[order.order_type].enabled;
    } else {
      let orderPrintBatchNo = order?.order_extended_info.print_batch_number;
      let parentItems = order?.order_extended_info.items.filter(
        (orderItem: any) => orderItem.order_item_parent_id == 0
      );

      let categoryItems: any;

      if (printRule.order_type_rules[order.order_type].template_rules.length) {
        let hasPrint = printRule.order_type_rules[
          order.order_type
        ].template_rules.map((templateRule: any) => {
          let templateId = Object.keys(templateRule)[0];
          let showOnlyItems =
            templateRule[Object.keys(templateRule)[0]].show_only_new_items;
          if (!showOnlyItems) {
            categoryItems = parentItems;
          } else if (orderPrintBatchNo) {
            categoryItems = parentItems.filter(
              (parentItem: any) =>
                parentItem.print_batch_no === orderPrintBatchNo
            );
          } else {
            categoryItems = parentItems.filter(
              (parentItem: any) => parentItem.is_new === true
            );
          }
          let categoryRules: any = [];
          printRule.category_print_rules.filter((categoryRule: any) => {
            categoryItems.map((item: any) => {
              if (item.cacategory_id == categoryRule.category_id) {
                categoryRules.push(categoryRule);
              }
            });
          });
          if (!categoryRules.length) {
            return true;
          } else {
            let categoryExists = categoryRules.some((categoryRule: any) => {
              return categoryRule.template_id == templateId;
            });
            return categoryExists;
          }
        });
        return hasPrint[0];
      } else {
        return false;
      }
    }
  }
};

const failedPaymentAttemptsCount = (orderId: number) => {
  let paymentFailedOrders: number[] = [];
  let failedAttemptCount = 0;
  // If the payment is failed for first time.
  if (!localStorage.paymentFailedOrders) {
    paymentFailedOrders.push(orderId);
    localStorage.setItem(
      "paymentFailedOrders",
      JSON.stringify(paymentFailedOrders)
    );
  } else {
    // Check if this order has previously failed attempts
    try {
      paymentFailedOrders = JSON.parse(
        localStorage.getItem("paymentFailedOrders") as any
      );
      paymentFailedOrders.push(orderId);
      failedAttemptCount = paymentFailedOrders.reduce(
        (total, fOrderId) => (orderId === fOrderId ? total + 1 : total + 0),
        0
      );
      localStorage.setItem(
        "paymentFailedOrders",
        JSON.stringify(paymentFailedOrders)
      );
    } catch (err) {
      // If this entry in localStorage has invalid data.
      localStorage.removeItem("paymentFailedOrders");
    }
  }
  return failedAttemptCount;
};

const validateCollectedPayments = (
  order: any,
  orderPayments: any,
  settlePaymentForOrder: () => void
) => {
  let failedAttempts = 0;
  let collectedTotalAmount = parseFloat(
    formatDecimal(
      orderPayments.payments.reduce(
        (total: number, payment: any) =>
          total + Math.abs(payment.amount_collected),
        0
      )
    )
  );
  let paymentsTotalAmount = parseFloat(
    formatDecimal(
      orderPayments.payments.reduce(
        (total: number, payment: any) => total + Math.abs(payment.amount),
        0
      )
    )
  );
  let orderTotal = order.order_extended_info.total_amount;
  let isLoyaltyPaidOrder =
    order.order_extended_info.payments.length === 1 &&
    order.order_extended_info.payments[0].name === "Loyalty";

  Sentry.addBreadcrumb({
    category: "payments",
    level: "debug",
    data: {
      capturedPaymentDetails: orderPayments.payments,
      isLoyaltyPaidOrder,
      orderTotal,
      collectedTotalAmount,
      paymentsTotalAmount,
    },
    message: `Captured payment for order ${order.order_id} with total ${orderTotal}`,
  });

  if (parseFloat(formatDecimal(orderTotal)) !== paymentsTotalAmount) {
    // Get no. of failed payment attempts for this order.
    failedAttempts = failedPaymentAttemptsCount(order.order_id);
    Sentry.setTag("failed_attempts", failedAttempts);

    let errorMessage =
      "Captured payment amount does not match the order total.";
    errorMessage +=
      orderTotal < paymentsTotalAmount
        ? " Excess amount captured in the payment payload. Payment total greater than order total"
        : " Split payment was initiated with small amount. But the remaining amount was not captured";
    // Send error event to sentry
    let error = new Error();
    error.name = "Payment Error";
    error.message = errorMessage;
    Sentry.captureException(error);

    // Option to overrride this error and settle the payment.
    if (localStorage.getItem("overrideFailedPayments") === "yes") {
      settlePaymentForOrder();
    } else {
      let title: any = <FormattedMessage id="payment.err" />;
      let text: any = <FormattedMessage id="payment_process_err" />;
      Swal.fire({
        icon: "error",
        title: title,
        text: text,
        allowOutsideClick: false,
      }).then((res) => {
        hardRefresh();
      });
    }
  } else {
    settlePaymentForOrder();
  }
};

const getPickupStatus = (orderStatus: any) => {
  let status;
  switch (orderStatus) {
    case "unconfirmed":
      status = <FormattedMessage id="tab.unconfirmed" />;
      break;
    case "ready":
      status = <FormattedMessage id="status.ready" />;
      break;
    case "new":
      status = <FormattedMessage id="status.waiting" />;
      break;
    case "delivered":
      status = <FormattedMessage id="tab.collected" />;
      break;
    default:
      status = "";
  }
  return status;
};

const getLogisticsVisibilityConfig = (
  orders: any[],
  logisticsPartners: any[],
  isRiderAppEnabled: boolean | undefined
) => {
  let ordersWithLogisticsPartnersAssigned = orders.filter((order: any) => {
    return order.logistics_job && order?.logistics_job?.status !== 3;
  });
  let ordersWithCompanyRidersAssigned = orders.filter((order: any) => {
    return order.rider_id && isRiderAppEnabled;
  });
  let ordersWithAssignableLogisticsPartners = orders.filter(
    (order: any) =>
      filterOrderLogisticsPartners(order.location_id, logisticsPartners)
        .length > 0
  );
  let activeZomatoGoOrdersExists = activeZomatoGoOrderCount(orders) > 0;
  let showColumnHeader =
    ordersWithLogisticsPartnersAssigned.length > 0 ||
    ordersWithCompanyRidersAssigned.length > 0 ||
    ordersWithAssignableLogisticsPartners.length > 0 ||
    activeZomatoGoOrdersExists ||
    isRiderAppEnabled;
  return {
    show: showColumnHeader,
    hasLogisticsPartnerAssignedOrders:
      ordersWithLogisticsPartnersAssigned.length > 0,
    hasCompanyRiderAssignedOrders:
      ordersWithCompanyRidersAssigned.length > 0,
    hasOrdersWithAssignablePartners:
      ordersWithAssignableLogisticsPartners.length > 0,
  };
};

const removeExpiredScheduledOrders = (orders: any[]) => {
  let todayTimestamp = new Date().getTime();
  return orders.filter((order) => {
    let time_difference = order.scheduled_for - todayTimestamp;
    return time_difference > 0;
  });
};

export {
  getOrdersStats,
  getOrderTime,
  getOrderFilterOptions,
  removeOrderFromList,
  getStatusTime,
  getDeliveredOrderStats,
  formatTime,
  addModifiersToItems,
  groupOrders,
  useSelectedBranch,
  useSelectedBranchWithRider,
  filterOrders,
  getTime,
  getOrderScheduledForDate,
  getBranchName,
  updateTotalAmount,
  getLogisticName,
  updateOrderTotalAmount,
  removeLoyaltyPayment,
  filterOrderLogisticsPartners,
  activeZomatoGoOrderCount,
  hasLocalPrint,
  validateCollectedPayments,
  getPickupStatus,
  getLogisticsVisibilityConfig,
  filterLabels,
  removeExpiredScheduledOrders,
};
