import React, {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  KeyboardEvent,
  useMemo,
} from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faTh,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { paymentsOperations } from "../../state/features/payments";
import { formatDecimal } from "../../helpers/itemCalculations";
import ccMapBackground from "../../images/ccmap.jpg";
import Keypad from "../keypad";
import Swal from "sweetalert2";
import { hardRefresh, numberInputRegex } from "../../helpers/utils";
import * as Sentry from "@sentry/react";
import {
  computeCashRounding,
  generateCashRoundingString,
} from "../../helpers/utils";
import { FormattedMessage, useIntl } from "react-intl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import { useAppStateSelector } from "../../hooks/useAppStateSelector";
import {
  PAYMENT_PARTNER,
  filterCashRoundingPaymentTypes,
  filterTakeawayPaymentPartnersByLocation,
  usePagination,
} from "./utils";
import {
  ItemSelectionGridFooter,
  ItemSelectionGridView,
} from "./itemSelectionGridView";
import useStripeConnectPayment from "./paymentPartners/stripeConnect/useStripeConnectPayment";
import TransactionStatusView from "./txnStatusView";
import useMagnatiPayment from "./paymentPartners/magnati/useMagnatiPayment";

interface IPaymentPopupProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  orderId: number;
  orderNo: number;
  paymentAmount: number;
  paymentAction: (orderId: number, payments: any) => any;
  payments: any[];
  currency: string;
  fetchPayments: () => any;
  orderPayments: any[];
  cash_rounding: any;
  isTakeAway?: boolean;
  locationId: number;
  initiateMagnatiTransaction(payload: any): Promise<any>;
  cancelMagnatiTransaction(transRefId: any, data: any): Promise<any>;
  processStripeConnectTerminalPayment(stripeLocationId: any): Promise<any>;
  initiateStripeConnectPaymentIntentCreate(payload: any): Promise<any>;
  fetchStripeConnectTerminalPayment(paymentIntentId: any): Promise<any>;
  cancelStripeConnectTerminalPayment(
    paymentIntentId: any,
    payload: any
  ): Promise<any>;
  locations: any[];
  currentTab: any;
}

const PaymentPopUp = (props: IPaymentPopupProps) => {
  const [showPaymentTypes, setShowPaymentTypes] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({
    display_name: "Select Payment",
  } as any);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentAmountCopy, setPaymentAmountCopy] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [refCodeInputValue, setRefCodeInputValue] = useState("");
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [paymentBreakdown, setPaymentBreakdown] = useState([] as any[]);
  const [showPaymentBreakDown, setShowPaymentBreakdown] = useState(false);
  const [showPaymentBoxCCInput, setShowPaymentBoxCCInput] = useState(false);
  const [showChangePreview, setShowChangePreview] = useState(false);
  const [paymentsList, setPaymentsList] = useState([] as any);
  const [disablePayButton, setPayButtonDisabled] = useState(false);

  // Keypad controls
  const [showKeypad, setShowKeypad] = useState(false);
  const [keyboardRef, setKeyboardRef] = useState(null as any);

  const paymentInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  // Cash rounding
  const [roundedAmount, setCashRoundedAmount] = useState(0);
  const [showRoundedString, setShowRoundedString] = useState(false);
  const [roundedString, setRoundedString] = useState("");
  const [excessAmount, setExcessAmount] = useState(0);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);

  const integratedPaymentPartners = [
    PAYMENT_PARTNER.MAGNATI,
    PAYMENT_PARTNER.STRIPE_CONNECT,
  ];

  const { payments: companyPaymentTypes, branches: companyLocations } =
    useAppStateSelector((state) => state);

  const locationPaymentPartner = useMemo(() => {
    let orderLocation = companyLocations.find(
      (loc: any) => loc.id === props.locationId
    );
    if (
      orderLocation?.payment_partners &&
      orderLocation?.payment_partners.length > 0
    ) {
      return orderLocation?.payment_partners[0];
    }
    return null;
  }, [JSON.stringify(companyLocations), props.locationId]);

  const paymentTypes = useMemo(
    () =>
      filterCashRoundingPaymentTypes(
        filterTakeawayPaymentPartnersByLocation(
          companyPaymentTypes,
          locationPaymentPartner,
          integratedPaymentPartners,
          props.isTakeAway
        )
      ),
    [locationPaymentPartner, locationPaymentPartner, props.isTakeAway]
  );

  const {
    currentPage: paymentTypesCurrentPage,
    navigateNextPage: navPaymentTypesNextPage,
    navigatePrevPage: navPaymentTypesPrevPage,
    paginatedItems: paginatedPaymentTypes,
    totalPages: paymentTypesTotalPages,
  } = usePagination({
    itemsList: paymentTypes,
  });

  const partnerTermDevices = useMemo(() => {
    if (!locationPaymentPartner) {
      return [];
    }
    return locationPaymentPartner.devices;
  }, [locationPaymentPartner]);

  const {
    currentPage: devicesCurrentPage,
    navigateNextPage: navDevicesNextPage,
    navigatePrevPage: navDevicesPrevPage,
    paginatedItems: paginatedDevicesPage,
    totalPages: devicesTotalPages,
  } = usePagination({ itemsList: partnerTermDevices });

  const [showDevices, setShowDevices] = useState(false);
  const [selectedPaymentPartner, setSelectedPaymentPartner] = useState("");

  const intl = useIntl();
  const formattedMessage = (formatId: any) => {
    const translatedMessage = intl.formatMessage({ id: formatId });
    return translatedMessage;
  };

  const terminalDeviceId = useRef<any>(null);

  const {
    stripeConnectError,
    initiatePaymentWithStripeConnect,
    txnStatus: stripeConnectTxnStatus,
    enableCancelTxn: enableStripeConnectCancelTxn,
    handleCancelTxnCtaClick: stripeConnectCancelCtaClick,
    handleRetryTxnCtaClick: stripeConnectRetryCtaClick,
  } = useStripeConnectPayment({
    amount: inputValue,
    orderId: props.orderId,
    orderNo: props.orderNo,
    getDeviceId: () => terminalDeviceId.current,
    clearDeviceId: () => terminalDeviceId.current = null,
    locationId: props.locationId,
    locationPartnerConfig: locationPaymentPartner,
    paymentTypeId: selectedPayment.id,
    handleCloseTxnStatusView() {
      setSelectedPaymentPartner("");
    },
    onTransactionSuccess(configs) {
      addPayment(configs);
    },
    onTransactionCancel() {},
    initiateStripeConnectPaymentIntentCreate:
      props.initiateStripeConnectPaymentIntentCreate,
    fetchStripeConnectTerminalPayment: props.fetchStripeConnectTerminalPayment,
    processStripeConnectTerminalPayment:
      props.processStripeConnectTerminalPayment,
    cancelStripeConnectTerminalPayment:
      props.cancelStripeConnectTerminalPayment,
  });

  const {
    txnDeclineReason: magnatiTxnDeclineReason,
    txnStatus: magnatiTxnStatus,
    enableCancelTxn: enableMagnatiCancelTxn,
    handleCancelTxnCtaClick: onMagnatiTxnCtaClick,
    handlePaymentWithMagnati,
  } = useMagnatiPayment({
    amount: inputValue,
    getDeviceId: () => terminalDeviceId.current,
    clearDeviceId: () => terminalDeviceId.current = null,
    locationId: props.locationId,
    paymentType: selectedPayment,
    initiateMagnatiTransaction: props.initiateMagnatiTransaction,
    cancelMagnatiTransaction: props.cancelMagnatiTransaction,
    handleCloseTxnStatusView() {
      setSelectedPaymentPartner("");
    },
    onTransactionSuccess(configs) {
      addPayment(configs);
    },
  });

  useEffect(() => {
    if (props.payments.length === 0) {
      props
        .fetchPayments()
        .catch((err: AxiosErrorResponse) => console.log(err));
    }
    Sentry.addBreadcrumb({
      category: "payments",
      message: "Initiated Payment",
      level: "debug",
    });
    setPaymentStates();
  }, []);

  useEffect(() => {
    let amountToPay: any;
    if (selectedPayment?.display_name) {
      if (
        props.cash_rounding?.enabled &&
        selectedPayment?.display_name == "Cash" &&
        props.isTakeAway
      ) {
        let cashroundedValues: any = computeCashRounding(
          props.paymentAmount,
          props.cash_rounding.rule,
          props.cash_rounding.interval
        );
        let cashRoundedString = generateCashRoundingString(
          props.paymentAmount,
          props.cash_rounding.rule,
          props.cash_rounding.interval
        );
        let roundedValue = formatDecimal(cashroundedValues.roundedAmount);
        let excessValue = formatDecimal(cashroundedValues.excessAmount);
        excessValue = parseFloat(cashroundedValues.excessAmount);
        setCashRoundedAmount(roundedValue);
        setRoundedString(cashRoundedString);
        if (roundedValue !== props.paymentAmount) {
          setShowRoundedString(true);
          setExcessAmount(excessValue);
        } else {
          setShowRoundedString(false);
        }
        amountToPay = formatDecimal(roundedValue);
      } else {
        amountToPay = formatDecimal(props.paymentAmount);
        setShowRoundedString(false);
      }
    }
    setTotalPaymentAmount(amountToPay);
  }, [props.paymentAmount, selectedPayment]);

  useEffect(() => {
    setPaymentStates();
    let breakdownTotal = paymentBreakdown.reduce(
      (total: number, item: any) => total + parseFloat(item.amount),
      0
    );
    let remainingValue = totalPaymentAmount - breakdownTotal;
    setInputValue(formatDecimal(remainingValue));
    setRemainingAmount(parseFloat(formatDecimal(remainingValue)));
  }, [totalPaymentAmount, paymentBreakdown]);

  useEffect(() => {
    if (paymentInputRef.current) {
      paymentInputRef.current.focus();
    }
    props.showModal && setPaymentStates();
  }, [props.showModal]);

  useEffect(() => {
    if (paymentTypes.length > 1) {
      // TODO:: Get default payment type from API.
      let defaultPayment = paymentTypes.find(
        (payment: any) => payment.primary === true
      );
      if (defaultPayment) {
        setSelectedPayment(defaultPayment);
      } else {
        setSelectedPayment(paymentTypes[0]);
      }
    }
  }, [paymentTypes]);

  useEffect(() => {
    if (showPaymentTypes && paymentBreakdown.length === 0) {
      $(".modal-content").removeClass("split-payment");
    } else if (showPaymentTypes && paymentBreakdown.length > 0) {
      $(".modal-content").removeClass("split-payment");
    } else if (!showPaymentTypes && paymentBreakdown.length === 0) {
      $(".modal-content").removeClass("split-payment");
    } else {
      $(".modal-content").addClass("split-payment");
    }
  }, [showPaymentTypes]);

  useEffect(() => {
    if (showPaymentBoxCCInput) {
      $(".modal-content").addClass("split-payment");
    }
  }, [showPaymentBoxCCInput]);

  useEffect(() => {
    showChangePreview && $(".modal-content").removeClass("split-payment");
  }, [showChangePreview]);

  useEffect(() => {
    if (paymentBreakdown.length > 0) {
      setShowPaymentBreakdown(true);
      $(".modal-content").addClass("split-payment");
      let breakDownContainerElement = document.querySelector(
        ".payment-breakdown-container"
      ) as HTMLDivElement;
      if (breakDownContainerElement) {
        breakDownContainerElement.scrollTop =
          breakDownContainerElement.scrollHeight;
      }
    } else {
      // setInputValue(formatDecimal(paymentAmount));
      setPaymentAmountCopy(0);
      setShowPaymentBreakdown(false);
      $(".modal-content").removeClass("split-payment");
    }
  }, [paymentBreakdown]);

  useEffect(() => {
    setPayButtonDisabled(!(parseFloat(inputValue) >= 0));
  }, [inputValue]);

  const handleSelectionWithPaymentPartner = (selectedPayment: any) => {
    if (partnerTermDevices.length > 1) {
      setShowDevices(true);
    } else {
      handleDeviceSelection({ name: selectedPayment, id: partnerTermDevices[0].device_id });
    }
  };

  const handleDeviceSelection = (data: any) => {
    terminalDeviceId.current = data.id;
    setShowDevices(false);
    setShowPaymentTypes(false);
    initiateWithPaymentPartner({ paymentPartner: selectedPayment.name })
  };

  const selectPayment = (payment: {
    id: number;
    name: string;
    icon: string;
  }) => {
    let selectedPayment = paymentTypes.find((d: any) => d.id === payment.id);
    if (
      integratedPaymentPartners.includes(
        selectedPayment.name as PAYMENT_PARTNER
      ) &&
      partnerTermDevices.length === 0
    ) {
      Swal.fire({
        icon: "warning",
        title: "Oops!",
        text: "No devices added. Please add a device by going to payment partner settings in your location setup",
      });
      return;
    }
    setSelectedPayment(selectedPayment);
    setShowPaymentTypes(!showPaymentTypes);
  };

  const setPaymentStates = () => {
    let amountToPay = formatDecimal(totalPaymentAmount);
    Sentry.addBreadcrumb({
      category: "payments",
      message: "Setting local state payment amount",
      data: {
        amountToPay,
      },
      level: "debug",
    });
    setPaymentAmount(parseFloat(amountToPay));
    setPaymentAmountCopy(parseFloat(amountToPay));
  };

  const flushPaymentStates = () => {
    setPaymentAmount(0);
    setRemainingAmount(0);
    setInputValue("");
    setBalanceAmount(0);
    setPaymentAmountCopy(0);
    setRefCodeInputValue("");
    setPaymentBreakdown([]);
    setShowPaymentBreakdown(false);
    setShowChangePreview(false);
    setShowPaymentBreakdown(false);
    setPaymentsList([]);
    setShowKeypad(false);
  };

  const addRefCode = (e: any) => {
    setRefCodeInputValue(e.target.value);
  };

  const addAmountForPayment = (e: React.ChangeEvent<HTMLInputElement>) => {
    let validInput = numberInputRegex.test(e.target.value)
      ? e.target.value
      : inputValue;
    paymentInputRef.current.value = validInput;
    setInputValue(validInput);
    if (keyboardRef !== null) {
      keyboardRef.current.setInput(validInput);
    }
  };

  const removePaymentFromBreakdown = (paymentIndex: number) => {
    let filteredPaymentBreakdown = paymentBreakdown.filter(
      (paymentMode: any, index: number) => paymentIndex !== index
    );
    let removedPayment = paymentBreakdown[paymentIndex];
    setPaymentBreakdown(filteredPaymentBreakdown);
    let breakdownTotal = filteredPaymentBreakdown.reduce(
      (total: number, item: any) => total + parseFloat(item.amount),
      0
    );
    let remainingAmt = formatDecimal(paymentAmount - breakdownTotal);
    Sentry.addBreadcrumb({
      category: "payments",
      data: {
        removedPayment,
        breakdownTotal,
        remainingAmt,
      },
    });
    setRemainingAmount(parseFloat(remainingAmt));
    setInputValue(remainingAmt);
  };

  const initiateWithPaymentPartner = ({
    paymentPartner,
  }: {
    paymentPartner: PAYMENT_PARTNER;
  }) => {
    switch (paymentPartner) {
      case PAYMENT_PARTNER.MAGNATI:
        setSelectedPaymentPartner(PAYMENT_PARTNER.MAGNATI);
        handlePaymentWithMagnati();
        break;
      case PAYMENT_PARTNER.STRIPE_CONNECT:
        setSelectedPaymentPartner(PAYMENT_PARTNER.STRIPE_CONNECT);
        initiatePaymentWithStripeConnect();
        break;
      default:
        break;
    }
  };

  // Check if selected payment requires reference code
  const addSelectedPayment = () => {
    if (
      integratedPaymentPartners.includes(selectedPayment.name) &&
      props.isTakeAway
    ) {
      handleSelectionWithPaymentPartner(selectedPayment.name);
    } else if (
      selectedPayment.requires_reference_code &&
      !showPaymentBoxCCInput
    ) {
      setShowPaymentBoxCCInput(true);
    } else if (
      selectedPayment.requires_reference_code &&
      showPaymentBoxCCInput
    ) {
      if (refCodeInputValue) {
        setShowPaymentBoxCCInput(false);
        addPayment({ refCode: refCodeInputValue });
      }
    } else {
      addPayment(undefined);
    }
  };

  const addPayment = (config: any) => {
    setRefCodeInputValue("");
    let paymentInputValue = parseFloat(inputValue);
    let skipRemainingSplit = false;
    if (parseFloat(formatDecimal(paymentInputValue)) < remainingAmount) {
      // split payment case
      setShowPaymentBoxCCInput(false);
      let partialPayment: any = {
        payment: selectedPayment,
        amount: parseFloat(formatDecimal(paymentInputValue)),
        amount_collected: parseFloat(formatDecimal(paymentInputValue)),
      };
      if (config?.refCode) {
        partialPayment["referenceCode"] = config?.refCode;
      }
      if (config?.metaData) {
        partialPayment["metaData"] = config?.metaData;
      }
      setPaymentBreakdown([...paymentBreakdown, partialPayment]);
      Sentry.addBreadcrumb({
        category: "payments",
        message: "Split payment initiated, adding payment to breakdown",
        data: {
          amount: partialPayment.amount,
          amount_collected: partialPayment.amount_collected,
          user_input_value: paymentInputValue,
        },
        level: "debug",
      });
      let computedRemainingAmount =
        paymentAmountCopy === 0
          ? paymentAmount - parseFloat(formatDecimal(paymentInputValue))
          : paymentAmountCopy - parseFloat(formatDecimal(paymentInputValue));
      setInputValue(formatDecimal(computedRemainingAmount));
      setPaymentAmountCopy(parseFloat(formatDecimal(computedRemainingAmount)));
      setRemainingAmount(parseFloat(formatDecimal(computedRemainingAmount)));
      if (paymentInputRef.current) {
        paymentInputRef.current.focus();
      }
      Sentry.addBreadcrumb({
        category: "payments",
        message: "Computed remaining amount values",
        data: {
          paymentAmountCopy,
          computedRemainingAmount,
        },
        level: "debug",
      });
    } else {
      // single payment or remaining payment from split payment
      let paymentBreakdownArray = paymentBreakdown;
      let selectedPaymentObject = selectedPayment;
      let balance =
        parseFloat(formatDecimal(paymentInputValue)) - remainingAmount;
      skipRemainingSplit =
        remainingAmount <= 0 &&
        parseFloat(formatDecimal(paymentInputValue)) <= 0 &&
        paymentAmount !== 0;
      if (parseFloat(formatDecimal(paymentInputValue)) >= remainingAmount) {
        Sentry.addBreadcrumb({
          category: "payments",
          message: "Excess amount has been given as input.",
          data: {
            remainingAmount,
            paymentInputValue: parseFloat(formatDecimal(paymentInputValue)),
            paymentAmount,
          },
        });
        if (paymentBreakdownArray.length > 0) {
          // Adds remaining value to the split
          let partialPaymentObject: any = {
            payment: selectedPayment,
            amount: parseFloat(formatDecimal(remainingAmount)),
            amount_collected: parseFloat(formatDecimal(paymentInputValue)),
          };
          if (config?.refCode) {
            partialPaymentObject["referenceCode"] = config?.refCode;
          }
          if (config?.metaData) {
            partialPaymentObject["metaData"] = config?.metaData;
          }
          paymentBreakdownArray.push(partialPaymentObject);
          Sentry.addBreadcrumb({
            category: "payments",
            message:
              "Split payment - transforming payload and appending it to breakdown array",
            data: {
              payment: partialPaymentObject,
              paymentBreakdownArray,
              skipRemainingSplit,
              balance,
            },
            level: "debug",
          });
        } else {
          selectedPaymentObject = {
            ...selectedPaymentObject,
            payment: selectedPayment,
            amount: parseFloat(formatDecimal(remainingAmount)),
            amount_collected: parseFloat(formatDecimal(paymentInputValue)),
          };
          if (config?.refCode) {
            selectedPaymentObject["referenceCode"] = config?.refCode;
          }
          if (config?.metaData) {
            selectedPaymentObject["metaData"] = config?.metaData;
          }
          Sentry.addBreadcrumb({
            category: "payments",
            message: "Single payment - transforming payload",
            data: {
              payment: selectedPaymentObject,
              paymentBreakdownArray,
              skipRemainingSplit,
              balance,
            },
            level: "debug",
          });
        }
        Sentry.addBreadcrumb({
          category: "payments",
          message: "Emptying states",
          data: {
            skipRemainingSplit,
            balance,
          },
          level: "debug",
        });
        setInputValue(formatDecimal(0));
        setPaymentAmountCopy(formatDecimal(0));
        setRemainingAmount(parseFloat(formatDecimal(0)));
        if (balance >= 0) {
          setBalanceAmount(parseFloat(formatDecimal(balance)));
        }
      }
      if (paymentBreakdownArray.length > 0) {
        let paymentsArray: any[] = [];
        paymentBreakdownArray.map((payment: any, index: number) => {
          let paymentPayload = createPaymentPayload(payment);
          paymentsArray.push(paymentPayload);
        });
        paymentAction(paymentsArray);
        Sentry.addBreadcrumb({
          category: "payments",
          message:
            "Split payment - Building payload and appending to payload list",
          data: {
            payments_list: paymentsArray,
          },
          level: "debug",
        });
      } else {
        if (skipRemainingSplit) {
          Sentry.addBreadcrumb({
            category: "payments",
            message: "Skipping remaining split payment",
            data: {
              skipRemainingSplit,
            },
            level: "debug",
          });
          paymentAction([]);
        } else {
          let paymentPayload = createPaymentPayload(selectedPaymentObject);
          paymentAction([paymentPayload]);
          Sentry.addBreadcrumb({
            category: "Payments",
            message: "Single payment - Building payload for single payment",
            data: {
              payments_list: [paymentPayload],
            },
            level: "debug",
          });
        }
      }
    }
  };

  const createPaymentPayload = (selectedPayment: any) => {
    let paymentObject: any = new Object();
    paymentObject["name"] = selectedPayment.payment.display_name;
    paymentObject["id"] = selectedPayment.payment.id;
    paymentObject["amount"] = selectedPayment.amount;
    paymentObject["amount_collected"] = selectedPayment.amount_collected;
    if (selectedPayment.referenceCode) {
      paymentObject["reference_code"] = selectedPayment.referenceCode;
    }
    if (selectedPayment.metaData) {
      paymentObject["meta_data"] = selectedPayment.metaData;
    }
    return paymentObject;
  };

  const paymentAction = (paymentsArray: any) => {
    setPaymentsList(paymentsArray);
    setShowChangePreview(true);
  };

  const validatePayment = (
    collectedPayments: any[],
    completePayment: () => void
  ) => {
    let totalCollectedAmount = parseFloat(
      formatDecimal(
        collectedPayments.reduce((totalAmount: number, payment: any) => {
          return totalAmount + Math.abs(payment.amount_collected);
        }, 0)
      )
    );
    Sentry.addBreadcrumb({
      category: "payments",
      level: "debug",
      data: {
        order_id: props.orderId,
        collected_total: totalCollectedAmount,
        payment_amount: paymentAmount,
        collected_payments: collectedPayments,
      },
      message: `Collected payment for amount ${paymentAmount} ${props.currency}`,
    });
    if (
      !(totalCollectedAmount >= parseFloat(formatDecimal(props.paymentAmount)))
    ) {
      console.debug(
        `[APP] Collected payment total of ${totalCollectedAmount} does not add up to order total ${paymentAmount}`,
        collectedPayments
      );
      let text: any = formattedMessage("payment.err1");
      Swal.fire({
        icon: "error",
        title: "Payment Error",
        text: text,
      }).then(() => {
        closePaymentModal();
        hardRefresh();
      });
    } else {
      console.debug(
        `[APP] Collected payment for amount ${paymentAmount} ${props.currency}`,
        collectedPayments
      );
      completePayment();
    }
  };

  const confirmPaymentChangePreview = () => {
    let collectedPayments: any[] = [];
    let paymentArray: any[] = paymentsList;
    if (showRoundedString) {
      paymentArray = handleCashRoundedPayment(paymentsList);
    }
    if (
      props.orderPayments.length === 1 &&
      props.orderPayments[0].name === "Loyalty"
    ) {
      if (props.paymentAmount === 0) {
        // If order is fully paid with Loyalty
        Sentry.addBreadcrumb({
          category: "payments",
          level: "debug",
          data: {
            collectedPayments,
          },
          message: `Confirming fully paid loyalty order`,
        });
        collectedPayments = [
          {
            ...props.orderPayments[0],
            amount_collected: props.orderPayments[0].amount,
          },
        ];
        validatePayment(collectedPayments, () =>
          props.paymentAction(props.orderId, {
            payments: collectedPayments,
          })
        );
      } else {
        // If order has partial payment done with Loyalty
        Sentry.addBreadcrumb({
          category: "payments",
          level: "debug",
          data: {
            collectedPayments,
          },
          message: `Confirming partially paid loyalty order`,
        });
        collectedPayments = [...paymentArray, props.orderPayments[0]];
        validatePayment(collectedPayments, () =>
          props.paymentAction(props.orderId, {
            payments: collectedPayments,
          })
        );
      }
    } else {
      Sentry.addBreadcrumb({
        category: "payments",
        level: "debug",
        data: {
          collectedPayments,
        },
        message: `Validating order from payment popup`,
      });
      validatePayment(paymentArray, () =>
        props.paymentAction(props.orderId, { payments: paymentArray })
      );
    }
    closePaymentModal();
  };

  const payButtonAction = () => {
    addSelectedPayment();
  };

  const backButtonAction = () => {
    setShowPaymentBoxCCInput(false);
    closePaymentModal();
  };

  const closePaymentModal = () => {
    flushPaymentStates();
    props.setShowModal(false);
  };

  const onEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      payButtonAction();
    }
  };

  const handleCashRoundedPayment = (paymentArray: any[]) => {
    let cashRoundingPaymentType: any = props.payments.find(
      (payment: any) => payment.display_name == "Cash Rounding"
    );
    let cashRoundedAmountObject: any = new Object();
    cashRoundedAmountObject["name"] = cashRoundingPaymentType.display_name;
    cashRoundedAmountObject["id"] = cashRoundingPaymentType.id;
    cashRoundedAmountObject["amount"] = excessAmount;
    cashRoundedAmountObject["amount_collected"] = excessAmount;

    return [...paymentArray, cashRoundedAmountObject];
  };

  return (
    <Modal
      show={props.showModal}
      dialogClassName="payment-modal-custom"
      backdrop="static"
      centered
    >
      <TransactionStatusView
        selectedPaymentPartner={selectedPaymentPartner}
        stripeConnectConfigs={{
          txnStatus: stripeConnectTxnStatus,
          showCancelTxnCta: enableStripeConnectCancelTxn,
          stripeConnectError,
          onTxnCancelCtaClick: stripeConnectCancelCtaClick,
          onRetryCtaClick: stripeConnectRetryCtaClick,
          onCloseTxnStatusViewClick: () => {
            setSelectedPaymentPartner("");
          },
        }}
        magnatiConfigs={{
          magnatiTxnDeclineReason,
          txnStatus: magnatiTxnStatus,
          showCancelTxnCta: enableMagnatiCancelTxn,
          onTxnCancelCtaClick: onMagnatiTxnCtaClick,
          onCloseTxnStatusViewClick: () => {
            setSelectedPaymentPartner("");
          },
        }}
      >
        <Modal.Body>
          {showChangePreview ? (
            <div className="payment-change-preview">
              <span className="muted">
                <FormattedMessage id="payment.change" /> ({props.currency})
              </span>
              <h1>
                <span className="change-amount">
                  {formatDecimal(balanceAmount)}
                </span>
              </h1>
            </div>
          ) : (
            <>
              {showPaymentTypes || showDevices ? (
                showDevices ? (
                  <ItemSelectionGridView
                    title="Select reader"
                    itemsList={paginatedDevicesPage.map((d: any) => ({
                      id: d.device_id,
                      name: d.name,
                    }))}
                    onSelect={handleDeviceSelection}
                  />
                ) : (
                  <ItemSelectionGridView
                    itemsList={paginatedPaymentTypes.map((d: any) => ({
                      id: d.id,
                      name: d.display_name,
                      icon: d.icon,
                    }))}
                    onSelect={selectPayment}
                  />
                )
              ) : (
                <div className="payment-container">
                  <div className="to-pay">
                    <span className="to-pay-label">
                      <FormattedMessage id="order.total_amount" /> (
                      {props.currency})
                    </span>
                    <strong className="total-amount">
                      {formatDecimal(paymentAmount)}
                    </strong>
                    {showRoundedString ? (
                      <p className="rounded-string">{roundedString}</p>
                    ) : null}
                  </div>
                  {showPaymentBreakDown && !showPaymentTypes ? (
                    <>
                      <p className="payment-breakdown-title py-3">
                        <FormattedMessage id="payment.breakdown" />
                      </p>
                      <div className="payment-breakdown-container">
                        <div className="breakdown-total">
                          <p>
                            <FormattedMessage id="order_popup_total" />:
                          </p>
                          <p>
                            {props.currency +
                              " " +
                              formatDecimal(paymentAmount)}
                          </p>
                        </div>
                        <table className="breakdown-table">
                          <tbody>
                            {paymentBreakdown.map(
                              (payment: any, index: number) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      {payment.payment.display_name}
                                      {payment.referenceCode
                                        ? `(${payment.referenceCode})`
                                        : null}
                                    </td>
                                    <td>
                                      {props.currency + " " + payment.amount}
                                    </td>
                                    <td>
                                      <button
                                        className="btn remove-payment"
                                        onClick={() =>
                                          removePaymentFromBreakdown(index)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTimes} />
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : null}
                  <div className="payment-input-container">
                    <div className="payment-box-container">
                      <div className="keypad-icon">
                        <FontAwesomeIcon
                          size="3x"
                          onClick={() => setShowKeypad(!showKeypad)}
                          icon={faTh}
                        />
                      </div>
                      <input
                        type="text"
                        className="amount-input"
                        onChange={addAmountForPayment}
                        value={inputValue}
                        ref={paymentInputRef}
                        onKeyPress={onEnterKeyPress}
                        autoFocus={true}
                      />
                    </div>
                    {showKeypad ? (
                      <Keypad
                        inputValue={inputValue}
                        showPaymentBoxCCInput={showPaymentBoxCCInput}
                        refCodeInputValue={refCodeInputValue}
                        setRefCodeInputValue={setRefCodeInputValue}
                        setInputValue={setInputValue}
                        setKeyboardRef={setKeyboardRef}
                        setShowKeypad={setShowKeypad}
                        paymentAction={payButtonAction}
                        isPaymentActionDisabled={disablePayButton}
                        showPaymentBreakDown={showPaymentBreakDown}
                      />
                    ) : null}
                    {showPaymentBoxCCInput ? (
                      <div
                        className="payment-box-cc-container"
                        style={{
                          background: `url(${ccMapBackground}) center center no-repeat`,
                        }}
                      >
                        <span
                          className="close"
                          onClick={() => setShowPaymentBoxCCInput(false)}
                        >
                          <FontAwesomeIcon icon={faTimes} size="sm" />
                        </span>
                        <span className="payment-type-label">
                          {selectedPayment ? selectedPayment.display_name : ""}
                        </span>
                        <div className="keypad-icon-sm">
                          <FontAwesomeIcon
                            size="2x"
                            onClick={() => setShowKeypad(!showKeypad)}
                            icon={faTh}
                          />
                        </div>
                        <FormattedMessage id="payment.cc">
                          {(placeholder) => (
                            <input
                              type="text"
                              autoFocus={true}
                              className="cc-number-field"
                              onKeyPress={onEnterKeyPress}
                              value={refCodeInputValue}
                              onChange={(e) => addRefCode(e)}
                              placeholder={placeholder}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    ) : null}
                    <button
                      className="btn btn-large btn-default btn-selected-payment-type"
                      onClick={() => setShowPaymentTypes(true)}
                    >
                      <span>
                        {selectedPayment ? selectedPayment.display_name : ""}
                      </span>
                      <span>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                    </button>
                    <div className="payment-input"></div>
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer
          className={`action-buttons ${
            showPaymentTypes || showDevices ? "action-payment-nav" : ""
          }`}
        >
          {showChangePreview ? (
            <div className="action-background">
              <button
                className="btn btn-back mr-2"
                onClick={confirmPaymentChangePreview}
              >
                <FormattedMessage id="ok" />
              </button>
            </div>
          ) : (
            <>
              {showPaymentTypes || showDevices ? (
                showDevices ? (
                  <ItemSelectionGridFooter
                    showCloseButtonAs="back"
                    currentPage={devicesCurrentPage}
                    totalPages={devicesTotalPages}
                    nextPageAction={navDevicesNextPage}
                    prevPageAction={navDevicesPrevPage}
                    onClose={() => {
                      setShowDevices((t) => !t);
                      setSelectedPaymentPartner("");
                    }}
                  />
                ) : (
                  <ItemSelectionGridFooter
                    currentPage={paymentTypesCurrentPage}
                    totalPages={paymentTypesTotalPages}
                    nextPageAction={navPaymentTypesNextPage}
                    prevPageAction={navPaymentTypesPrevPage}
                    onClose={() => setShowPaymentTypes((t) => !t)}
                  />
                )
              ) : (
                <>
                  <button className="btn btn-back" onClick={backButtonAction}>
                    <FormattedMessage id="back" />
                  </button>
                  <button
                    className="btn btn-pay"
                    onClick={payButtonAction}
                    disabled={disablePayButton}
                  >
                    {parseFloat(inputValue) < remainingAmount ? (
                      <FormattedMessage id="add" />
                    ) : (
                      <FormattedMessage id="keypad_pay" />
                    )}
                  </button>
                </>
              )}
            </>
          )}
        </Modal.Footer>
      </TransactionStatusView>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  let payments = state.payments || [];
  let currency = state.company.company_details.currency;
  let cash_rounding = state.company.company_details.cash_rounding;
  let locations = state.branches;
  let currentTab = state.currentTab;

  return { payments, currency, cash_rounding, locations, currentTab };
};

const mapDispatchToProps = {
  fetchPayments: paymentsOperations.fetchPayments,
  initiateMagnatiTransaction: paymentsOperations.initiateMagnatiTransaction,
  cancelMagnatiTransaction: paymentsOperations.cancelMagnatiTransaction,
  processStripeConnectTerminalPayment:
    paymentsOperations.processStripeConnectTerminalPayment,
  initiateStripeConnectPaymentIntentCreate:
    paymentsOperations.initiateStripeConnectPaymentIntentCreate,
  fetchStripeConnectTerminalPayment:
    paymentsOperations.fetchStripeConnectTerminalPayment,
  cancelStripeConnectTerminalPayment:
    paymentsOperations.cancelStripeConnectTerminalPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPopUp);
