import React, {
  ReactElement,
  createRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { connect } from "react-redux";
import { currentTabOperations } from "../../state/features/currentTab";
import { pickupOperations } from "../../state/features/pickup";
import NoOrders from "../noOrders";
import Order from "../order";
import OrdersFilterSection from "../ordersFilterSection";
import {
  filterOrders,
  hasLocalPrint,
  validateCollectedPayments,
} from "../../helpers/orderFunctions";
import { getEnvValueFor } from "../../helpers/utils";
import { reprintsOperations } from "../../state/features/reprints";
import { printSettings } from "../../state/features";
import { FormattedMessage } from "react-intl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import { AnimateOrderTransitions } from "../animateOrderTransitions";
import { AnimateTableTransition } from "../animateTableTransitions";
import { SearchResults } from "./searchResults";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";
import OrderFiltersTab from "../orderFilters";
import { ORDER_STATUS } from "../../types/orderStatus";
import useOrderFilter from "../../hooks/useOrderFilter";

interface IPickupWaitingForCollectionProps {
  markOrderCollected: any;
  readyOrders: any;
  selectedBranch: any;
  company: any;
  setCurrentTab: any;
  refreshRequired: any;
  getLocalPrint?: any;
  printSettings?: any;
}

function PickupWaitingForCollection(props: IPickupWaitingForCollectionProps) {
  const [markCollected, setMarkCollected] = useState(false);
  const [filteredReadyOrders, setFilteredReadyOrders] = useState([] as any);
  const [selecteOrderId, setSelectedOrderId] = useState(0);
  const [disableButton, setDisable] = useState(false);
  const { enableEntryTransition } = useEntryTransitionConfig();
  const { searchResultOrders, onSearchChange } =
    useOrderFilter(filteredReadyOrders);

  useEffect(() => {
    props.setCurrentTab("pickup.ready");
  }, []);

  const markOrderCollected = (orderId: number, orderPayments: any) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    let validationEnabledCompanies: any = JSON.parse(
      getEnvValueFor("VALIDATION_ENABLED_COMPANIES") || "[]"
    );
    let order: any = null;
    let collectedOrderIndex = props.readyOrders.findIndex(
      (order: any) => order.order_id === orderId
    );
    if (validationEnabledCompanies.includes(props.company.company_id)) {
      console.debug("[APP] Initiating payment validation");
      if (collectedOrderIndex !== -1) {
        order = props.readyOrders[collectedOrderIndex];
        if (
          order.order_extended_info.payments.length === 0 ||
          (order.order_extended_info.payments.length === 1 &&
            order.order_extended_info.payments[0].name === "Loyalty")
        ) {
          validateCollectedPayments(order, orderPayments, () => {
            handleMarkOrderCollected(orderId, orderPayments, order);
          });
        } else {
          handleMarkOrderCollected(orderId, orderPayments, order);
        }
      } else {
        console.debug("[APP] Skipping validation");
        if (collectedOrderIndex !== -1) {
          order = props.readyOrders[collectedOrderIndex];
          handleMarkOrderCollected(orderId, orderPayments, order);
        }
      }
    } else {
      if (collectedOrderIndex !== -1) {
        order = props.readyOrders[collectedOrderIndex];
        handleMarkOrderCollected(orderId, orderPayments, order);
      }
    }
    setMarkCollected(true);
  };

  const handleMarkOrderCollected = (
    orderId: number,
    orderPayments: any,
    order: any
  ) => {
    props
      .markOrderCollected(orderId, orderPayments)
      .then((response: any) => {
        setDisable(false);
        if (response.payload.status === 200) {
          setTimeout(() => {
            if (
              (order.order_type == "call_center" ||
                order.order_type == "online") &&
              hasLocalPrint(order, props.printSettings)
            ) {
              window.open(
                `${getEnvValueFor(
                  "SAPAAD_CORE_BASE_URL"
                )}/local_prints/pays_bill_for_takeaway_order?order_id=${orderId}`,
                "PRINT",
                `resizable=yes,width=400,height=500,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=0,top=0`
              );
            }
          }, 300);
        }
      })
      .catch((err: AxiosErrorResponse) => {
        console.log(err);
        setDisable(false);
      });
  };

  useEffect(() => {
    setFilteredReadyOrders(props.readyOrders);
  }, [JSON.stringify(props.readyOrders)]);

  useEffect(() => {
    if (markCollected) {
      setMarkCollected(false);
    }
  }, [markCollected]);

  const onFilterChanges = (filterOption: any) => {
    setFilteredReadyOrders(filterOrders(filterOption, props.readyOrders));
  };

  const showSearchResults = useMemo(() => {
    return Boolean(searchResultOrders.length > 0);
  }, [searchResultOrders.length]);

  const searchResults = useMemo(() => {
    return searchResultOrders.map((o) => ({
      ...o,
      id: o.order_id,
      ref: createRef(),
    }));
  }, [JSON.stringify(searchResultOrders)]);

  return (
    <div className="new-orders">
      <OrderFiltersTab
        currentTab={ORDER_STATUS.PICKUP_READY}
        onSearch={(searchValue: string) => onSearchChange(searchValue)}
      />
      {showSearchResults ? (
        <SearchResults
          disableButton={disableButton}
          markOrderCollected={markOrderCollected}
          orders={searchResults}
          selecteOrderId={selecteOrderId}
        />
      ) : (
        <AnimateTableTransition
          isVisited={enableEntryTransition}
          show={filteredReadyOrders.length > 0 && !showSearchResults}
          component={({ ref }) => (
            <table
              className="orders-table"
              cellPadding="0"
              cellSpacing="0"
              ref={ref}
            >
              <thead>
                <tr>
                  <th></th>
                  <th style={{ width: "180px" }}>
                    <FormattedMessage id="order.no" />.
                  </th>
                  <th>
                    <FormattedMessage id="order.order_time" />
                  </th>
                  <th>
                    <FormattedMessage id="order.elapsed" />
                  </th>
                  <th>
                    <FormattedMessage id="order.cust_or_tel" />
                  </th>
                  <th>
                    <FormattedMessage id="order.agent" />
                  </th>
                  <th>
                    <FormattedMessage id="order.amount" />
                  </th>
                  <th className="text-center">
                    <FormattedMessage id="order.action" />
                  </th>
                </tr>
              </thead>
              <tbody className="orders-list-table-body">
                <AnimateOrderTransitions
                  enableEntryTransition={enableEntryTransition}
                  orders={filteredReadyOrders}
                  component={({ item: order }) => (
                    <Order
                      orderType="pickup"
                      orderStatus="ready"
                      key={order.order_id}
                      handleOrderState={markOrderCollected}
                      disableButton={
                        order.order_id === selecteOrderId && disableButton
                      }
                      item={order}
                    />
                  )}
                />
              </tbody>
            </table>
          )}
        />
      )}
      {!filteredReadyOrders.length ? <NoOrders /> : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let readyOrders = state.order.pickup.ready_pickup_orders.orders || [];
  let selectedBranch = state.company?.selected_branch;
  let company = state.company.company_details;
  let refreshRequired = state.order.home_delivery.ready_orders.refresh_required;
  let printSettings = state.printSettings;
  return {
    readyOrders,
    selectedBranch,
    company,
    refreshRequired,
    printSettings,
  };
};

const mapDispatchToProps = {
  markOrderCollected: pickupOperations.markOrderCollected,
  setCurrentTab: currentTabOperations.setCurrentTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickupWaitingForCollection);
