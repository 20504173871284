export const FETCH_PAYMENTS = "payments/FETCH_PAYMENTS";
export const FETCH_PAYMENTS_SUCCESS = "payments/FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAIL = "payments/FETCH_PAYMENTS_FAIL";
export const INITIATE_MAGNATI_TRANSACTION =
  "payments/INITIATE_MAGNATI_TRANSACTION";
export const INITIATE_MAGNATI_TRANSACTION_SUCCESS =
  "payments/INITIATE_MAGNATI_TRANSACTION_SUCCESS";
export const INITIATE_MAGNATI_TRANSACTION_FAIL =
  "payments/INITIATE_MAGNATI_TRANSACTION_FAIL";
export const CANCEL_MAGNATI_TRANSACTION =
  "payments/CANCEL_MAGNATI_TRANSACTION";
export const CANCEL_MAGNATI_TRANSACTION_SUCCESS =
  "payments/CANCEL_MAGNATI_TRANSACTION_SUCCESS";
export const CANCEL_MAGNATI_TRANSACTION_FAIL =
  "payments/CANCEL_MAGNATI_TRANSACTION_FAIL";
export const PROCESS_STRIPE_CONNECT_TERMINAL_PAYMENT =
  "payments/PROCESS_STRIPE_CONNECT_TERMINAL_PAYMENT";
export const PROCESS_STRIPE_CONNECT_PAYMENT_INTENT =
  "payments/PROCESS_STRIPE_CONNECT_PAYMENT_INTENT";
export const FETCH_STRIPE_CONNECT_TERMINAL_PAYMENT =
  "payments/FETCH_STRIPE_CONNECT_TERMINAL_PAYMENT";
export const CANCEL_STRIPE_CONNECT_TERMINAL_PAYMENT =
  "payments/CANCEL_STRIPE_CONNECT_TERMINAL_PAYMENT";