import * as types from "./types";
import * as orderEventTypes from "../orderEvents/types";
import { removeExpiredScheduledOrders } from "../../../helpers/orderFunctions";

const initialOrdersState = {
  is_loading: true,
  orders: [],
} as any;

const scheduledOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  let index;
  switch (action.type) {
    case types.FETCH_SCHEDULED_ORDERS_SUCCESS:
      let orders = action.payload.data || [];
      return {
        ...state,
        orders: removeExpiredScheduledOrders(orders),
        is_loading: false,
      };
    case types.REMOVE_SCHEDULED_ORDER:
      let filtered_orders = state.orders.filter((order: any) => {
        return order.order_id !== action.payload;
      });
      return { ...state, orders: filtered_orders, is_loading: false };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED:
      if (action.payload.order_status !== "new" && !action.payload.scheduled ) {
        return state; // do nothing
      }
      if (action.payload.order_status === "new" && !action.payload.scheduled ) {
        return state; // do nothing
      }
      if (action.payload.order_status === "unconfirmed" && action.payload.scheduled) {
        return state; // do nothing
      }
      // Push order into the new orders list if it doesn't exist
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id; 
      });
      if (index !== -1){ // order is already in the list
        return state; // do nothing
      }

      if (action.payload.order_payload == undefined || action.payload.order_payload == {}){
        // Order payload is not present
        return { ...state, refresh_required: true }
      } else {
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        return { ...state, orders: updatedOrderList, refresh_required: false };
      }
    default:
      return state;
  }
};

const reducer = scheduledOrdersReducer;
export default reducer;
